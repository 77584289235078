'use client'

import { useCallback, useState } from 'react'
import { MdMenu, MdClose } from 'react-icons/md'
import { Dialog, Popover } from '@headlessui/react'
import { Link } from 'atoms'

const TW_NAV_CLASSES = `flex flex-row w-full items-center justify-end py-6 px-5 lg:justify-center lg:mx-auto lg:max-w-5xl`
const TW_PANEL_CLASSES = `fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-zinc-800 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10`
const TW_NAVBAR_LINK_CLASSES = `text-sm font-semibold leading-6 text-gray-300`
const TW_DRAWER_LINK_CLASSES = `-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 hover:bg-zinc-800`

function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const onClickLink = useCallback(() => { setMobileMenuOpen(false) }, [])

  return (
    <nav className={TW_NAV_CLASSES} aria-label="Global">
      <div className="flex lg:hidden">
        <button
          type="button"
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-300"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <MdMenu className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <Popover.Group className="hidden lg:flex lg:gap-x-12">
        <Link href="/" className={TW_NAVBAR_LINK_CLASSES} onClick={onClickLink}>
          Home
        </Link>
        <Link href="/contact" className={TW_NAVBAR_LINK_CLASSES} onClick={onClickLink}>
          Contact
        </Link>
        <Link href="/technologies" className={TW_NAVBAR_LINK_CLASSES} onClick={onClickLink}>
          Technologies
        </Link>
      </Popover.Group>

      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className={TW_PANEL_CLASSES}>
          <div className="flex items-center justify-between">
            <Link href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">hillig.digital</span>
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-300"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <MdClose className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  href="/"
                  className={TW_DRAWER_LINK_CLASSES}
                  onClick={onClickLink}
                >
                  Home
                </Link>
                <Link
                  href="/contact"
                  className={TW_DRAWER_LINK_CLASSES}
                  onClick={onClickLink}
                >
                  Contact
                </Link>
                <Link
                  href="/imprint"
                  className={TW_DRAWER_LINK_CLASSES}
                  onClick={onClickLink}
                >
                  Imprint
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </nav>
  )
}

export default Navbar
